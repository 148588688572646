<template>
  <v-card>
    <v-row>
      <v-col cols="6">
        <v-card-title class="align-start">
          <span>Caja Actual</span>
          <!-- <v-spacer></v-spacer> -->
        </v-card-title>
      </v-col>
      <v-col cols="6">
        <v-card-title class="align-start">
          <span>Día Anterior</span>
          <!-- <v-spacer></v-spacer> -->
        </v-card-title>
      </v-col>
    </v-row>

    <v-card-text class="">
      <v-row>
        <v-col cols="6">
          <div class="d-flex align-center">
            <h4 class="text-2xl font-weight-semibold">
              <v-card-subtitle><span>Ingresos</span></v-card-subtitle>
              $ {{ ingresosToday }}
            </h4>

            <div class="d-flex align-center">
              <v-icon

                size="40"
                color="success"
              >
                {{ icons.mdiMenuUp }}
              </v-icon>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-card-subtitle class="align-center">
            <br><br>
            <h4 class="text-2xl font-weight-semibold">
              <span>$&nbsp;{{ ingresosYesterday }}</span>
            </h4>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="">
      <v-row>
        <v-col cols="6">
          <div class="d-flex align-center">
            <h4 class="text-2xl font-weight-semibold">
              <v-card-subtitle><span>Egresos</span></v-card-subtitle>
              $ {{ egresosToday }}
            </h4>

            <div class="d-flex align-center">
              <v-icon

                size="40"
                color="error"
              >
                {{ icons.mdiMenuDown }}
              </v-icon>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-card-subtitle>
            <br><br>
            <h4 class="text-2xl font-weight-semibold">
              <span>   $&nbsp;{{ egresosYesterday }}</span>
            </h4>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="">
      <v-row>
        <v-col cols="6">
          <div class="d-flex align-center">
            <h4 class="text-2xl font-weight-semibold">
              <v-card-subtitle><span>Saldo</span></v-card-subtitle>
              $ {{ saldoToday }}
            </h4>

            <div class="d-flex align-center">
              <v-icon
                v-if="saldoToday <0"
                size="40"
                color="error"
              >
                {{ icons.mdiMenuDown }}
              </v-icon>
              <v-icon
                v-else-if="saldoToday >=0"

                size="40"
                color="success"
              >
                {{ icons.mdiMenuUp }}
              </v-icon>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
     
          <v-card-subtitle class="align-center">
            <br><br>
            <h4 class="text-2xl font-weight-semibold">
            <span>$&nbsp;{{ saldoYesterday }}</span>
          </h4>
          </v-card-subtitle>
          <h4 class="font-weight-medium">
            <!-- Comparado con $ {{ ultimaCaja }} del último cierre. -->
          </h4>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiMenuUp, mdiMenuDown } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    return {
      icons: {
        mdiDotsVertical, mdiMenuUp, mdiMenuDown,

      },
      ingresosToday: 0,
      egresosToday: 0,
      saldoToday: 0,
      ingresosYesterday: 0,
      egresosYesterday: 0,
      saldoYesterday: 0,

    }
  },

  mounted() {
    // mounted
    this.getCashToday()
    this.getCashYesterday()
  },
  methods: {
    async getReportDashboard() {
      this.url = `${this.$store.getters.urlBase}/api/Report/dashboard`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
      // console.log(respuesta.data);
        this.employeeData = respuesta.data

      // this.getListarDiasMantenimiento();
      } else {
      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
      }
    },
    async getCashToday() {
      this.url = `${this.$store.getters.urlBase}/api/Report/cashRegisterOperation`
      const options = {
        headers: {
          'content-type': 'application/json',
        },
        params: {
          fromDate: this.moment().format('MM-DD-YYYY'),
          toDate: this.moment().format('MM-DD-YYYY'),
        },

        url: this.url,
      }
      await axios
        .get(this.url, options)
      // eslint-disable-next-line no-return-assign
        .then(response => {
          this.ingresosToday = response.data.entrys
          this.egresosToday = response.data.outputs
          this.saldoToday = response.data.balance
        })
        .catch(error => {

        })
    },
    async getCashYesterday() {
      this.url = `${this.$store.getters.urlBase}/api/Report/cashRegisterOperation`
      const options = {
        headers: {
          'content-type': 'application/json',
        },
        params: {
          fromDate: this.moment().add(-1, 'd').format('MM-DD-YYYY'),
          toDate: this.moment().add(-1, 'd').format('MM-DD-YYYY'),

        },

        url: this.url,
      }
      await axios
        .get(this.url, options)
      // eslint-disable-next-line no-return-assign
        .then(response => {
          this.ingresosYesterday = response.data.entrys
          this.egresosYesterday = response.data.outputs
          this.saldoYesterday = response.data.balance
        })
        .catch(error => {

        })
    },
  },

}
</script>
