<template>
  <v-card :loading="loadingClients">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Estadísticas Generales</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <span>Clientes Registrados</span>
    </v-card-subtitle>

    <v-card-text
      v-if="!loadingClients"
    >
      <v-row v-if="statisticsData.length > 0">
        <v-col
          v-for="data in statisticsData"
          :key="data.state"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.state).color"
            rounded
            class="elevation-1"
            @click="goTO(resolveStatisticsIconVariation (data.state).url )"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.state).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.state }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.cant }}
            </h3>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-else
        justify="center"
      >
        <h1>Sin Clientes cargados</h1>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiAccountClock, mdiTrendingUp, mdiAccountSearch, mdiAccountCheck, mdiAccountCancel } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    return {
      // icons
      icons: {
        mdiAccountSearch,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiAccountCheck,
        mdiAccountClock,
      },
      mdiAccountCancel,
      dashboard: null,
      statisticsData: [],
      loadingClients: false,
    }
  },
  mounted() {
    this.getDashboard()
  },
  methods: {
    goTO(url) {
      this.$router.push({ name: 'abmclient', params: { clientState: url } })

      // this.$router.push({ name: 'abmclient' })
    },
    resolveStatisticsIconVariation(data) {
      if (data === 'Con seguimiento especial') return { icon: mdiAccountSearch, color: 'danger', url: 5 }
      if (data === 'Con alto riesgo de insolvencia') return { icon: mdiAccountCancel, color: 'error', url: 4 }
      if (data === 'Situación normal') return { icon: mdiAccountCheck, color: 'success', url: 6 }
      if (data === 'Atrasado') return { icon: mdiAccountClock, color: 'warning', url: 1 }
      if (data === 'Cancelado se renueva') return { icon: mdiAccountClock, color: '#21C9FF', url: 3 }
      if (data === 'Cancelado no se renueva') return { icon: mdiAccountCancel, color: '#10A8B8', url: 2 }

      return { icon: mdiAccountOutline, color: 'success' }
    },
    async getDashboard() {
      this.url = `${this.$store.getters.urlBase}/api/report/dashboard`

      const params = {}
      params.personId = parseInt(this.$store.getters.usuario.user_personid)

    //  params.loggedPersonId = parseInt(this.$store.getters.usuario.user_personid)
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params: params,
        url: this.url,
      }
      await axios(this.url, options)
      .then(response => {
          this.statisticsData = response.data
          this.loadingClients = false
        })
        .catch(error => {
          this.loadingClients = false
        })
      // await axios
      //   .get(this.url, params)
      // // eslint-disable-next-line no-return-assign
      //   .then(response => {
      //     this.statisticsData = response.data
      //     this.loadingClients = false
      //   })
      //   .catch(error => {
      //     this.loadingClients = false
      //   })
    },
  },
}
</script>
